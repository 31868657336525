<template>
  <div>
    <ejs-dialog
      ref="createCartInfoDetailPopup"
      :header="`카트정보 상세`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1180"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onCartInfoDetailPopupClosed"
      :cssClass="
        isCreate
          ? $t('className.popup.productCodePopupAdd')
          : $t('className.popup.productCodePopupModify')
      "
    >
      <div class="window cartInformationDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <div class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">카트정보 상세</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="delete" v-if="!this.isCreate">
                          <erp-button
                              button-div="DELETE"
                              :ignore="isPopupOpened"
                              :is-shortcut-button="true"
                              @click.native="onDeleteClicked"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <div class="article-left">
                        <!-- 아코디언 : accordion / 닫힘 : close -->
                        <section class="article-section section-0101">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">카트 기본정보</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">카트번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartNoInput"
                                                id="cartInfoCartNoInput"
                                                name="cartInfoCartNoInput"
                                                v-model="cartInfoData.cartNo"
                                                @change="onCartNoChanged"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">카트명</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartNameInput"
                                                id="cartInfoCartNameInput"
                                                name="cartInfoCartNameInput"
                                                v-model="cartInfoData.cartName"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">카트종류</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                ref="cartKindDivDropdown"
                                                name="cartKindDivDropdown"
                                                id="cartKindDivDropdown"
                                                v-model="cartInfoData.cartKind"
                                                :dataSource="cartKindCodes"
                                                :allowFiltering="false"
                                                :fields="fields"
                                                cssClass="body-data-dropdown"
                                              ></ejs-dropdownlist>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">구입일자</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-date
                                                ref="buyDayPicker"
                                                type="body-data"
                                                id="buyDayPicker"
                                                name="buyDayPicker"
                                                :format="'YYYY-MM-DD'"
                                                v-model="
                                                  cartInfoData.purchsDate
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">구입금액</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-number
                                                ref="cartInfoCartPriceInput"
                                                id="cartInfoCartPriceInput"
                                                name="cartInfoCartPriceInput"
                                                v-model="cartInfoData.purchsAmt"
                                                :min="0"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">사용년수</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-number
                                                ref="cartInfoCartYearInput"
                                                id="cartInfoCartYearInput"
                                                name="cartInfoCartYearInput"
                                                v-model="cartInfoData.useYycnt"
                                                :min="0"
                                                :propMaxLength="3"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field correspondent">
                                      <!-- 필수 : required -->
                                      <div class="title">거래처</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group input">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartBcncInput"
                                                id="cartInfoCartBcncInput"
                                                name="cartInfoCartBcncInput"
                                                v-model="cartInfoData.bcncName"
                                                @change="onBcncNameChanged"
                                              />
                                            </div>
                                          </li>
                                          <li class="item form-group button">
                                            <ul class="button">
                                              <li class="search">
                                                <erp-button
                                                  button-div="GET"
                                                  @click.native="
                                                    onBcncSelectPopupShow
                                                  "
                                                  >검색</erp-button
                                                >
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">관리번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartNumberInput"
                                                id="cartInfoCartNumberInput"
                                                name="cartInfoCartNumberInput"
                                                v-model="cartInfoData.manageNo"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">용도</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartTypeInput"
                                                id="cartInfoCartTypeInput"
                                                name="cartInfoCartTypeInput"
                                                v-model="cartInfoData.prpos"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">제작회사</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartPrdComInput"
                                                id="cartInfoCartPrdComInput"
                                                name="cartInfoCartPrdComInput"
                                                v-model="
                                                  cartInfoData.mnfctCompany
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">공급회사</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartSupComInput"
                                                id="cartInfoCartSupComInput"
                                                name="cartInfoCartSupComInput"
                                                v-model="
                                                  cartInfoData.suplyCompany
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">모델명</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartModelNameInput"
                                                id="cartInfoCartModelNameInput"
                                                name="cartInfoCartModelNameInput"
                                                v-model="cartInfoData.modelName"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">형식</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="cartInfoCartStyleInput"
                                                id="cartInfoCartStyleInput"
                                                name="cartInfoCartStyleInput"
                                                v-model="cartInfoData.fom"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">사용연료</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                ref="cartFuelDivDropdown"
                                                name="cartFuelDivDropdown"
                                                v-model="
                                                  cartInfoData.cartUseFuel
                                                "
                                                :dataSource="cartFuelCodes"
                                                :allowFiltering="false"
                                                :fields="fields"
                                                cssClass="body-data-dropdown"
                                              ></ejs-dropdownlist>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title">일련번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                  ref="cartInfoCartSnInput"
                                                  id="cartInfoCartSnInput"
                                                  name="cartInfoCartSnInput"
                                                  v-model="cartInfoData.sn"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                        <!-- 아코디언 : accordion / 닫힘 : close -->
                        <section class="article-section section-0102">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">카트 상태</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">카트상태</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                ref="cartStatusDivDropdown"
                                                name="cartStatusDivDropdown"
                                                id="cartStatusDivDropdown"
                                                v-model="
                                                  cartInfoData.cartStatus
                                                "
                                                :dataSource="cartStatusCodes"
                                                :allowFiltering="false"
                                                :fields="fields"
                                                cssClass="body-data-dropdown"
                                              ></ejs-dropdownlist>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">폐기여부</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group check">
                                            <!-- 포커스 : focus -->
                                            <ul class="check">
                                              <li>
                                                <label>
                                                  <input
                                                    type="radio"
                                                    v-model="
                                                      cartInfoData.dsuseFlag
                                                    "
                                                    :value="false"
                                                  />
                                                  <i></i>
                                                  <div class="label">사용</div>
                                                </label>
                                              </li>
                                              <li>
                                                <label>
                                                  <input
                                                    type="radio"
                                                    v-model="
                                                      cartInfoData.dsuseFlag
                                                    "
                                                    :value="true"
                                                  />
                                                  <i></i>
                                                  <div class="label">폐기</div>
                                                </label>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">폐기일자</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-date
                                                ref="disCartDayPicker"
                                                id="disCartDayPicker"
                                                type="body-data"
                                                :disabled="
                                                  !cartInfoData.dsuseFlag
                                                "
                                                :format="'YYYY-MM-DD'"
                                                v-model="cartInfoData.dsuseDate"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field remarks">
                                      <!-- 필수 : required -->
                                      <div class="title">비고(1)</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-textarea
                                                ref="cartInfoClass1Input"
                                                id="cartInfoClass1Input"
                                                name="cartInfoClass1Input"
                                                v-model="cartInfoData.remarks1"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field remarks">
                                      <!-- 필수 : required -->
                                      <div class="title">비고(2)</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-textarea
                                                ref="cartInfoClass2Input"
                                                id="cartInfoClass2Input"
                                                name="cartInfoClass2Input"
                                                v-model="cartInfoData.remarks2"
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div class="article-right">
                        <section class="article-section section-0103">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">카트사진</div>
                            </div>
                            <div class="header-right">
                              <ul class="header-button">
                                <li class="registration">
                                  <ejs-uploader
                                    ref="cartFileUploader"
                                    name="cartFileUploader"
                                    :buttons="buttons"
                                    :allowedExtensions="
                                      cartImageFileUploaderExtensions
                                    "
                                    :multiple="false"
                                    maxFileSize="10000000"
                                    :selected="onFileSelect"
                                  ></ejs-uploader>
                                </li>
                                <li class="delete">
                                  <erp-button
                                      button-div="DELETE"
                                      :is-icon-custom="true"
                                      @click.native="onDeleteImage"
                                  >
                                    삭제
                                  </erp-button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="section-body">
                            <div
                              class="body-photo"
                              id="cartImagePreviewContainer"
                            >
                              <div
                                v-if="cartInfoData.imagePath"
                                id="previewImage"
                                class="item"
                                :style="{
                                  backgroundImage:
                                    'url(' + cartInfoData.imagePath + ')',
                                }"
                              ></div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </article>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  @click.native="onSaveClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="cartInfoDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <bcnc-select-popup
      v-if="showBcnsSelectPopup"
      ref="bcncSelectPopup"
      @popupClosed="onBcncSelectPopupClose"
      @confirmBcnc="onConfirmBcnc"
    ></bcnc-select-popup>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import bcncSelectPopup from "@/views/game-progress/popup/BcncSelectPopup";
import {
  putCartInfoDetail,
  postCartInfoDetail,
  deleteCartInfoDetail,
  getMaxCartNo,
  getCartInfo,
} from "@/api/cartInfoRegistration";
import { getFormattedDate } from "@/utils/date";
import { getAccountInfo } from "@/api/accountRegistration";
import InputDate from "@/components/common/datetime/InputDate";
import { validateFormRefs, uploadImage } from "@/utils/formUtil";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "cartInfoDetailPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputTextarea,
    InputText,
    InputNumber,
    InputDate,
    bcncSelectPopup,
    ErpButton,
  },
  beforeDestroy() {
    if (this.isCartImageUploadedAndNotSaved) {
      this.deleteCartFile();
    }
  },
  computed: {
    isPopupOpened() {
      return this.showBcnsSelectPopup;
    },
  },
  data() {
    return {
      isCreate: true,
      showBcnsSelectPopup: false,
      isCartImageUploadedAndNotSaved: false,
      cartKindCodes: commonCodesGetCommonCode("CART_KIND"),
      cartFuelCodes: commonCodesGetCommonCode("CART_USE_FUEL"),
      cartStatusCodes: commonCodesGetCommonCode("CART_STATUS"),
      fields: { text: "comName", value: "comCode" },
      originCartNo: null,
      cartInfoData: {
        cartNo: null,
        dsuseFlag: false,
        imagePath: null,
        imageId: null,
        purchsAmt: 0,
        useYycnt: 1,
        cartName: null,
        cartUseFuel: null,
        cartStatus: null,
        sn: null,
      },
      buttons: {
        browse: "등록",
      },
      cartImageFileUploaderExtensions: ".png, .jpg, .jpeg, .gif, .bmp",
      validateRefList: {
        cartInfoCartNoInput: { required: true },
        cartInfoCartNameInput: { required: true },
        cartKindDivDropdown: { required: true },
        cartStatusDivDropdown: { required: true },
      },
      isSave: false,
    };
  },
  methods: {
    validateFormRefs,
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async onShowCartInfoDetailPopup(isCreate, cartId, data) {
      this.isCreate = isCreate;

      if (!this.isCreate) {
        await getCartInfo(cartId).then((result) => {
          if (result.status === "OK") {
            this.cartInfoData = result.value;
            this.originCartNo = JSON.parse(JSON.stringify(result.value.cartNo));
          }
        });
      } else {
        this.cartInfoData = {
          imageId: null,
          imagePath: null,
          cartUseFuel: this.cartFuelCodes[0].comCode,
          cartKind: this.cartKindCodes[0].comCode,
          cartStatus: this.cartStatusCodes[0].comCode,
          ...data,
        };
      }
      this.$refs.createCartInfoDetailPopup.show();
    },
    onCartInfoDetailPopupClosed() {
      this.$emit("popupClosed", this.isSave);
    },
    cartInfoDetailPopupClose() {
      this.$refs.createCartInfoDetailPopup.hide();
    },
    async onCreateCartInfo() {
      await postCartInfoDetail(this.cartInfoData).then(() => {
        getMaxCartNo().then((result) => {
          this.cartInfoData = {
            cartNo: result.value,
            dsuseFlag: false,
            useYycnt: 1,
            purchsDate: getFormattedDate(new Date()),
            purchsAmt: 0,
            imagePath: null,
            imageId: null,
            cartUseFuel: this.cartFuelCodes[0].comCode,
            cartName: null,
            suplyCompany: null,
            bcncName: null,
            bcncId: null,
            prpos: null,
            mnfctCompany: null,
            manageNo: null,
            modelName: null,
            fom: null,
            dsuseDate: null,
            remarks1: null,
            remarks2: null,
            cartKind: this.cartKindCodes[0].comCode,
            cartStatus: this.cartStatusCodes[0].comCode,
          };
          this.isCartImageUploadedAndNotSaved = false;
        });
        this.infoToast(this.$t("main.popupMessage.saved"));
      });
    },
    async onUpdateCartInfo() {
      await putCartInfoDetail(this.cartInfoData).then(() => {
        this.isCartImageUploadedAndNotSaved = false;
        this.infoToast(this.$t("main.popupMessage.saved"));
      });
    },
    async onSaveClicked() {
      if (!this.validate()) {
        return;
      }
      if (this.cartInfoData.dsuseFlag && this.cartInfoData.dsuseDate === null) {
        this.errorToast("폐기일자를 입력해주세요");
        return;
      }

      if (this.cartInfoData.dsuseFlag && this.cartInfoData.dsuseDate) {
        if (
          !(await this.confirm(
            `카트${this.cartInfoData.cartNo} 를 폐기처리 하시겠습니까?`
          ))
        ) {
          return;
        }
      }

      if (this.isCreate) {
        await this.onCreateCartInfo();
      } else {
        await this.onUpdateCartInfo();

        await getCartInfo(this.cartInfoData.cartId).then((result) => {
          if (result.status === "OK") {
            this.cartInfoData = result.value;
            this.originCartNo = JSON.parse(JSON.stringify(result.value.cartNo));
          }
        });
      }

      this.isSave = true;
    },
    async onDeleteClicked() {
      if (await this.confirm("삭제하시겠습니까?")) {
        deleteCartInfoDetail(this.cartInfoData.cartId).then(() => {
          this.$emit("delete");
        });
      }
    },
    onBcncSelectPopupClose() {
      this.showBcnsSelectPopup = false;
      this.cartInfoData.bcncName = null;
      this.cartInfoData.bcncId = null;
    },
    onBcncSelectPopupShow() {
      this.showBcnsSelectPopup = true;
      this.$nextTick(() => {
        this.$refs.bcncSelectPopup.showSelectPopup();
      });
    },
    async onBcncNameChanged(args) {
      if (args.event) {
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.cartInfoData.bcncName = null;
          this.cartInfoData.bcncId = null;
        }

        if (args.previousValue !== args.value) {
          try {
            const {
              value: { tsBcncList },
            } = await getAccountInfo(args.value, null, null);
            this.bcncList = tsBcncList;
            if (tsBcncList.length !== 1) {
              this.showBcnsSelectPopup = true;
              this.$nextTick(() => {
                this.$refs.bcncSelectPopup.showSelectPopup(args.value);
              });
            } else {
              this.cartInfoData.bcncName = this.bcncList[0].bcncName;
              this.cartInfoData.bcncId = this.bcncList[0].bcncId;
            }
          } catch (e) {
            console.error("getAccountInfo.err.===>", e);
          }
        }
      }
    },
    async onCartNoChanged(args) {
      if (args.event) {
        if (args.value === null) {
          // 빈 칸인 경우에는 미 설정으로
          this.cartInfoData.cartNo = null;
          this.errorToast("카트번호를 입력해야 합니다");
          return;
        }
        if (parseInt(args.value) < 0) {
          this.cartInfoData.cartNo = JSON.parse(
            JSON.stringify(this.originCartNo)
          );
          return;
        }
        // TODO : 카트번호 변경후 바로 저장 버튼 클릭하는 경우 카트번호 change 이벤트를 실행하게되서 카트번호 체크가 2번 실행됨.
        // if (this.originCartNo !== args.value.toString()) {
        //   await getCheckCartNo(args.value, this.cartInfoData.dsuseFlag, this.cartInfoData.cartId).catch(
        //     () => {
        //       this.cartInfoData.cartNo = JSON.parse(JSON.stringify(this.originCartNo));
        //     }
        //   );
        // }
      }
    },
    async onFileSelect(args) {
      const file = args.event.target.files[0];

      try {
        const uploadResponse = await uploadImage(
          this.cartInfoData.imageId,
          "CART",
          args.filesData,
          file
        );
        if (uploadResponse) {
          this.cartInfoData.imageId = uploadResponse.imageId;
          this.cartInfoData.imagePath = uploadResponse.imageURL;
          this.isCartImageUploadedAndNotSaved = true;
        }
      } catch (e) {
        alert(e.message);
      } finally {
        this.$refs.cartFileUploader.clearAll(); // 다시 upload 하지 않도록 clear
      }
    },
    onConfirmBcnc(args) {
      if (args && args.length > 0) {
        this.cartInfoData.bcncName = args[0].bcncName;
        this.cartInfoData.bcncId = args[0].bcncId;
      }
      this.showBcnsSelectPopup = false;
    },
    onDeleteImage() {
      this.$refs.cartFileUploader.clearAll();

      if (this.cartInfoData.imageId && this.cartInfoData.imagePath) {
        this.deleteCartFile();
      }
    },
    async deleteCartFile() {
      try {
        await GolfErpAPI.deleteImage(this.cartInfoData.imageId);
        this.cartInfoData.imageId = null;
        this.cartInfoData.imagePath = null;
        this.isCartImageUploadedAndNotSaved = false;
      } catch (e) {
        console.error("imageDelete.err.===>", e);
      }
    },
    formatDate(d) {
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
