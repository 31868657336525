import request from "@/utils/request";

/**
 * @return Promise
 */
export function getCartInfoList(cartKind, cartNo, dsuseFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/cart/info/list",
    method: "get",
    params: {
      cartKind,
      cartNo,
      dsuseFlag,
    },
  });
}

/**
 * @return Promise
 */
export function getCartInfo(cartId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/cart/info/detail",
    method: "get",
    params: {
      cartId,
    },
  });
}

/**
 * @return Promise
 */
export function getMaxCartNo() {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/cart/info/detail/cart-no-max",
    method: "get",
  });
}

/**
 * @return Promise
 */
export function putCartInfoDetail(cartRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/cart/info/detail",
    method: "put",
    data: cartRequest,
  });
}

/**
 * @return Promise
 */
export function postCartInfoDetail(cartRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/cart/info/detail",
    method: "post",
    data: cartRequest,
  });
}

/**
 * @return Promise
 */
export function deleteCartInfoDetail(cartId) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/cart/info/detail",
    method: "delete",
    params: {
      cartId,
    },
    data: {},
  });
}
