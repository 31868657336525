<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field cartDivision">
            <div class="title">카트종류</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="cartKindCodeDiv"
                  v-model="searchOptions.cartKind"
                  :dataSource="searchConditionOptions.cartKindCodes"
                  :allowFiltering="false"
                  :fields="searchConditionOptions.fields"
                  cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="field cartNumber">
            <div class="title">카트번호</div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchOptions.searchValue" />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="searchOptions.used"
                        :value="true"
                      />
                      <i></i>
                      <div class="label">사용</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="searchOptions.used"
                        :value="false"
                      />
                      <i></i>
                      <div class="label">폐기</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onGetCartInfoList"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">카트 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="cartinforegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      @click.native="onCartInfoDetailPopupShow"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="cartInfoGrid"
              :provides="cartInfoGridOptions.provides"
              :columns="cartInfoGridOptions.columns"
              :dataSource="cartInfoList"
              :allowResizing="false"
              :allowSelection="true"
              :allowPaging="true"
              :pageSettings="cartInfoGridOptions.pageSettings"
              :editSettings="cartInfoGridOptions.editSettings"
              @headerCellInfo="cartInfoGridCustomiseHeaderCell"
              @queryCellInfo="cartInfoGridCustomiseCell"
              @recordClick="cartInfoGridClick"
              @actionComplete="cartInfoGridActionComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <cart-info-detail-popup
      v-if="showCartInfoDetail"
      ref="cartInfoDetailPopup"
      @popupClosed="onCartInfoDetailPopupClosed"
      @delete="onDeleteCartInfo"
    ></cart-info-detail-popup>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.cartDivision .content .item {width: 120px;}
body .appContent .lookup-condition .field.cartNumber .content .item {width: 60px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";

import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { Edit, ForeignKey, Page, ExcelExport } from "@syncfusion/ej2-vue-grids";
import cartInfoDetailPopup from "@/views/game-progress/popup/CartInfoDetailPopup";
import { getCartInfoList, getMaxCartNo } from "@/api/cartInfoRegistration";
import Vue from "vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { getFormattedDate } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";

import ErpButton from "@/components/button/ErpButton.vue";
export default {
  name: "CartInfoRegistration",
  mixins: [routeViewMixin, commonMixin],
  components: {
    InputText,
    EjsGridWrapper,
    cartInfoDetailPopup,
    ErpButton,
  },
  created() {
    this.searchConditionOptions.cartKindCodes.unshift({
      comCode: "%",
      comName: "전체",
    });
  },
  data() {
    return {
      count: 0,
      cartInfoList: [],
      showCartInfoDetail: false,
      cartInfoCnt: 0,
      searchConditionOptions: {
        cartKindCodes: commonCodesGetCommonCode("CART_KIND"),
        fields: { text: "comName", value: "comCode" },
      },
      searchOptions: {
        cartKind: "%",
        searchValue: "",
        used: true,
      },
      editTemplate() {
        return {
          template: Vue.component("editOption", {
            template:
              '<div v-if="data.dsuseFlag || data.cartStatus !== `NOR`" style="color:red">{{data.cartNo}}</div>' +
              "<div v-else>{{data.cartNo}}</div>",
            data() {
              return { data: { data: {} } };
            },
          }),
        };
      },
      cartStatusTemplate() {
        return {
          template: Vue.component("editOption", {
            template:
              '<div v-if="data.cartStatus !== `NOR`" style="color:red">{{data.foreignKeyData.comName}}</div>' +
              "<div v-else>{{data.foreignKeyData.comName}}</div>",
            data() {
              return { data: { data: {} } };
            },
          }),
        };
      },
    };
  },
  computed: {
    isPopupOpened() {
      return this.showCartInfoDetail;
    },
    cartInfoGridOptions() {
      return {
        provides: [Edit, ForeignKey, Page, ExcelExport],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "cartId",
            headerText: "위약번호",
            allowEditing: false,
            type: "number",
            format: "N",
            visible: false,
          },
          {
            field: "cartNo",
            headerText: "카트번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
          },
          {
            field: "cartName",
            headerText: "카트명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 180,
            textAlign: "left",
          },
          {
            field: "cartKind",
            headerText: "카트종류",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.searchConditionOptions.cartKindCodes,
          },
          {
            field: "purchsDate",
            headerText: "구입일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
          },
          {
            field: "purchsAmt",
            headerText: "구입금액",
            allowEditing: false,
            type: "number",
            format: "N",
            minWidth: 16,
            width: 100,
            textAlign: "right",
          },
          {
            field: "useYycnt",
            headerText: "사용년수",
            allowEditing: false,
            type: "number",
            format: "N",
            minWidth: 16,
            width: 100,
            textAlign: "center",
          },
          {
            field: "manageNo",
            headerText: "관리번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "left",
          },
          {
            field: "prpos",
            headerText: "용도",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
          },
          {
            field: "mnfctCompany",
            headerText: "제작회사",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "left",
          },
          {
            field: "modelName",
            headerText: "모델명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "left",
          },
          {
            field: "sn",
            headerText: "일련번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 140,
            textAlign: "left",
          },
          {
            field: "cartUseFuel",
            headerText: "사용연료",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "CART_USE_FUEL",
          },
          {
            field: "cartStatus",
            headerText: "카트상태",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "CART_STATUS",
          },
          {
            field: "dsuseFlag",
            headerText: "폐기여부",
            allowEditing: false,
            type: "boolean",
            minWidth: 16,
            width: 100,
            textAlign: "Center",
            displayAsCheckBox: true,
          },
          {
            field: "dsuseDate",
            headerText: "폐기일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "remarks1",
            headerText: "비고1",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 240,
            textAlign: "left",
          },
          {
            field: "remarks2",
            headerText: "비고2",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 240,
            textAlign: "left",
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    onGetCartInfoList() {
      this.fetchCartInfoList();
    },
    async fetchCartInfoList() {
      const {
        value: { cartInfoList, cartInfoCnt },
      } = await getCartInfoList(
        this.searchOptions.cartKind !== "%"
          ? this.searchOptions.cartKind
          : undefined,
        this.searchOptions.searchValue === null ||
        this.searchOptions.searchValue.trim() === ""
          ? undefined
          : this.searchOptions.searchValue,
        !this.searchOptions.used
      );
      this.cartInfoCnt = cartInfoCnt;
      this.cartInfoList = cartInfoList;
    },
    async onCartInfoDetailPopupShow() {
      await getMaxCartNo().then((result) => {
        this.showCartInfoDetail = true;
        this.$nextTick(() => {
          this.$refs.cartInfoDetailPopup.onShowCartInfoDetailPopup(true, null, {
            cartNo: result.value,
            dsuseFlag: false,
            useYycnt: 1,
            purchsDate: getFormattedDate(new Date()),
            purchsAmt: 0,
            cartName: null,
          });
        });
      });
    },
    onCartInfoDetailPopupClosed(isSave) {
      this.showCartInfoDetail = false;
      if (isSave) {
        this.onSaveCartInfo();
      }
    },
    numberTemplate() {
      return {
        template: Vue.component("editOption", {
          template: "<span>{{parseInt(data.index) + 1}}</span>",
          data() {
            return {
              data: {
                data: {},
              },
            };
          },
        }),
      };
    },
    cartInfoGridClick(args) {
      if (
        args.column &&
        (args.column.field === "cartNo" || args.column.field === "cartName")
      ) {
        this.showCartInfoDetail = true;
        this.$nextTick(() => {
          this.$refs.cartInfoDetailPopup.onShowCartInfoDetailPopup(
            false,
            args.rowData.cartId,
            JSON.parse(JSON.stringify(args.rowData))
          );
        });
      }
    },
    onSaveCartInfo() {
      this.fetchCartInfoList();
    },
    onDeleteCartInfo() {
      this.fetchCartInfoList();
      this.$refs.cartInfoDetailPopup.onCartInfoDetailPopupClosed();
    },
    cartInfoGridCustomiseHeaderCell(args) {
      if (
        args.cell.column.field === "cartNo" ||
        args.cell.column.field === "cartName"
      ) {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    cartInfoGridCustomiseCell(args) {
      const {
        cell,
        column: { field, type },
        data,
      } = args;
      if (field === "cartNo") {
        if (data.dsuseFlag || data.cartStatus !== "NOR") {
          cell.style.color = "#FF0000";
        } {
          cell.style.color = "#000000";
        }
      }
      if (field === "cartNo" || field === "cartName") {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (field === "cartStatus") {
        if (data.cartStatus !== "NOR") {
          cell.style.color = "#FF0000";
        } else {
          cell.style.color = "#000000";
        }
      }
      if (type.toLowerCase() === "number" && !data[field] && field !== "manageNo") {
        cell.innerText = "-";
      }
    },
    cartInfoGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.cartInfoGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.cartInfoGrid.excelExport();
    },
  },
};
</script>
