<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="createBcncSelectPopup"
      :header="`거래처 검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="667"
      height="600"
      :isModal="true"
    >
      <div class="window correspondentSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field correspondentName">
                    <div class="title">거래처명/사업자번호</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          id="bcncSelectPopupSearchKeywordTextBox"
                          v-model="searchKeyword"
                          :tabindex="searchKeywordTabIndex"
                          @keydown.enter="onSearchKeywordKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    :tabindex="-1"
                    @click.native="getBcncList"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <div class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">거래처 목록</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid" v-if="isSelector">
                      <ejs-grid-wrapper
                        ref="bcncListGrid"
                        id="bcncListGrid"
                        :allowResizing="true"
                        :dataSource="bcncList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :allowPaging="true"
                        :columns="bcncListGridColumn"
                        :provides="grid"
                        :pageSettings="pageSettings"
                        :isSelectedRowRetain="false"
                        :isInPopup="true"
                        @onGridDialogDoubleClickedOrEnterKeyed="
                          onBcncListGridDoubleClick
                        "
                        @dataBound="onBcncListGridDataBound"
                      />
                    </div>
                    <div v-if="!isSelector">
                      <div class="body-article">
                        <!-- 아코디언 : accordion / 닫힘 : close -->
                        <section class="article-section section-0101">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">기본정보 1</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">
                                        거래처명
                                      </div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="bcncNameInput"
                                                id="bcncNameInput"
                                                name="bcncNameInput"
                                                v-model="
                                                  bcncPopupAddData.bcncName
                                                "
                                              />
                                            </div>
                                            <div class="validation">
                                              <div
                                                class="e-tip-content"
                                              ></div>
                                              <div class="e-tip-top"></div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">
                                        사업자번호
                                      </div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item date form-group">
                                            <div class="form">
                                              <ejs-maskedtextbox
                                                id="bcncBizNo"
                                                name="bcncBizNo"
                                                mask="000-00-00000"
                                                v-model="
                                                  bcncPopupAddData.bizNo
                                                "
                                                class="e-input"
                                                type="text"
                                                v-on:keydown.native="
                                                  preventEnterEvent
                                                "
                                              ></ejs-maskedtextbox>
                                            </div>
                                            <div class="validation">
                                              <div
                                                class="e-tip-content"
                                              ></div>
                                              <div class="e-tip-top"></div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <!-- 필수 : required -->
                                      <div class="title required">
                                        대표자명
                                      </div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="bcncRprsntvNameInput"
                                                id="bcncRprsntvNameInput"
                                                name="bcncRprsntvNameInput"
                                                v-model="
                                                  bcncPopupAddData.rprsntvName
                                                "
                                              />
                                            </div>
                                            <div class="validation">
                                              <div
                                                class="e-tip-content"
                                              ></div>
                                              <div class="e-tip-top"></div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field dateOfBirth">
                                      <div class="title required">
                                        법인번호
                                      </div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item date form-group">
                                            <div class="form">
                                              <ejs-maskedtextbox
                                                id="bcncCprNo"
                                                name="bcncCprNo"
                                                mask="000000-0000000"
                                                v-model="
                                                  bcncPopupAddData.cprNo
                                                "
                                                class="e-input"
                                                type="text"
                                                v-on:keydown.native="
                                                  preventEnterEvent
                                                "
                                              ></ejs-maskedtextbox>
                                            </div>
                                            <div class="validation">
                                              <div
                                                class="e-tip-content"
                                              ></div>
                                              <div class="e-tip-top"></div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title required">
                                        거래처구분
                                      </div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <ejs-dropdownlist
                                                id="bcncDivDropdownList"
                                                name="bcncDivDropdownList"
                                                v-model="
                                                  bcncPopupAddData.bcncDiv
                                                "
                                                :dataSource="bcncDivCodes"
                                                :allowFiltering="false"
                                                :fields="bcncDivCodesOptions"
                                                cssClass="body-data-dropdown"
                                              ></ejs-dropdownlist>
                                            </div>
                                            <div class="validation">
                                              <div
                                                class="e-tip-content"
                                              ></div>
                                              <div class="e-tip-top"></div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                        <!-- 아코디언 : accordion / 닫힘 : close -->
                        <section class="article-section section-0102">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">기본정보 2</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field">
                                      <div class="title">업태</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="bcncBizcndInput"
                                                id="bcncBizcndInput"
                                                name="bcncBizcndInput"
                                                v-model="
                                                  bcncPopupAddData.bizCnd
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">업종</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="bcncIndutyInput"
                                                id="bcncIndutyInput"
                                                name="bcncIndutyInput"
                                                v-model="
                                                  bcncPopupAddData.induty
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                        <!-- 아코디언 : accordion / 닫힘 : close -->
                        <section class="article-section section-0103">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">담당자/연락처</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <li class="field">
                                      <div class="title">담당자</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="bcncManagerInput"
                                                id="bcncManagerInput"
                                                name="bcncManagerInput"
                                                v-model="
                                                  bcncPopupAddData.manager
                                                "
                                              />
                                            </div>
                                            <div class="validation">
                                              <div
                                                class="e-tip-content"
                                              ></div>
                                              <div class="e-tip-top"></div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">이메일</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="bcncMailInput"
                                                id="bcncMailInput"
                                                name="bcncMailInput"
                                                v-model="
                                                  bcncPopupAddData.mail
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">전화번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <component-telephone
                                                id="bcncTelNo"
                                                name="bcncTelNo"
                                                v-model="
                                                  bcncPopupAddData.telNo
                                                "
                                                @keydown.native="
                                                  preventEnterEvent
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">팩스번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <component-telephone
                                                id="bcncFaxNo"
                                                name="bcncFaxNo"
                                                v-model="
                                                  bcncPopupAddData.faxNo
                                                "
                                                v-on:keydown.native="
                                                  preventEnterEvent
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">우편번호</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li
                                            class="item form-group post"
                                            v-if="postRerender"
                                          >
                                            <div class="form">
                                              <input-text
                                                id="bcncPostNo"
                                                name="bcncPostNo"
                                                v-model="
                                                  bcncPopupAddData.postNo
                                                "
                                                @keydown.native="
                                                  preventEnterEvent
                                                "
                                              />
                                            </div>
                                            <div class="validation">
                                              <div
                                                class="e-tip-content"
                                              ></div>
                                              <div class="e-tip-top"></div>
                                            </div>
                                          </li>
                                          <li class="item form-group button">
                                            <ul class="button">
                                              <li>
                                                <erp-button
                                                  button-div="GET"
                                                  @click.native="
                                                    onPostCodeClick()
                                                  "
                                                >
                                                  우편번호
                                                </erp-button>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">주소1</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="bcncAddr1Input"
                                                id="bcncAddr1Input"
                                                name="bcncAddr1Input"
                                                v-model="
                                                  bcncPopupAddData.addr1
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">주소2</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-text
                                                ref="bcncAddr2Input"
                                                id="bcncAddr2Input"
                                                name="bcncAddr2Input"
                                                v-model="
                                                  bcncPopupAddData.addr2
                                                "
                                              />
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <erp-button button-div="SAVE" @click.native="onSaveBcncInfo">
                        저장
                      </erp-button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" @click.native="confirmBcncInfo"> 확인 </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onBcncSelectPopupClosed">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
      <daum-postcode-popup
        v-if="isDaumPostcodePopupOpen"
        ref="daumPostcodePopup"
        :position="{ x: 'center', y: 'center' }"
        :isModal="true"
        @popupClose="daumPostcodePopupClosed"
        @popupConfirmed="daumPostcodePopupConfirmed"
      />
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import DaumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import { getAccountInfo } from "@/api/accountRegistration";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  Page,
} from "@syncfusion/ej2-vue-grids";
import InputText from "@/components/common/text/InputText";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "bcncSelectPopup",
  mounted() {
    this.bcncPopupAddForm = new FormValidator(
      "#bcncPopupAddForm",
      this.bcncPopupAddFormOption
    );
    this.bcncPopupAddData.bcncDiv = this.bcncDivCodes[0].comCode;
  },
  components: {
    ComponentTelephone,
    InputText,
    DaumPostcodePopup,
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [commonMixin],
  data() {
    return {
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, Page],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      searchKeywordTabIndex: -1,
      searchKeyword: null,
      isDaumPostcodePopupOpen: false,
      postRerender: true,
      pageSettings: { pageSize: 50 },
      bcncDivCodes: commonCodesGetCommonCode("BCNC_DIV"),
      bcncDeptCodes: commonCodesGetCommonCode("BCNC_DEPT"),
      bcncDivCodesOptions: { text: "comName", value: "comCode" },
      bcncList: [],
      isSelector: true,
      bcncEditSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
      },
      bcncListGridColumn: [
        {
          field: "bcncId",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "bcncName",
          headerText: "거래처명",
          width: 180,
          type: "string",
        },
        {
          field: "bizNo",
          headerText: "사업자번호",
          width: 120,
          type: "string",
        },
        {
          field: "rprsntvName",
          headerText: "대표자명",
          width: 90,
          type: "string",
        },
        {
          field: "bcncDiv",
          headerText: "거래처구분",
          width: 90,
          isCommonCodeField: true,
          groupCode: "BCNC_DIV",
          type: "string",
        },
        {
          field: "bcncDept",
          headerText: "거래처부서",
          width: 90,
          isCommonCodeField: true,
          groupCode: "BCNC_DEPT",
          type: "string",
        },
      ],
      bcncPopupAddData: {
        bcncName: null,
        bizNo: null,
        rprsntvName: null,
        cprNo: null,
        bcncDiv: null,
        bizCnd: null,
        induty: null,
        manager: null,
        mail: null,
        telNo: null,
        faxNo: null,
        postNo: null,
        addr1: null,
        addr2: null,
      },
      bcncPopupAddForm: {},
      bcncPopupAddFormOption: {
        customPlacement: function (inputElement, errorElement) {
          inputElement
            .closest(".form-group")
            .querySelector(".validation")
            .querySelector(".e-tip-content")
            .appendChild(errorElement); // errorElement 넣기
        },
        validationComplete: function (args) {
          if (args.status === "failure") {
            args.element
              .closest(".form-group")
              .querySelector(".validation").style.display = "block";
          } else {
            args.element
              .closest(".form-group")
              .querySelector(".validation").style.display = "none";
          }
        },
        rules: {
          bcncNameInput: {
            required: [true, "거래처명을 입력해 주십시오"],
          },
          bcncBizNo: {
            regex: [/^((?!___-__-_____).)*$/, "사업자번호를 입력해 주십시오"],
          },
          bcncRprsntvNameInput: {
            required: [true, "대표자명을 입력해 주십시오"],
          },
          bcncCprNo: {
            regex: [/^((?!_______-______).)*$/, "법인번호를 입력해 주십시오"],
          },
        },
      },
    };
  },
  methods: {
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async showSelectPopup(args) {
      if (args) {
        this.searchKeyword = args;
        this.fetchBcncList();
      }
      this.$refs.createBcncSelectPopup.show();
    },
    onBcncSelectPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createBcncSelectPopup.hide();
    },
    async fetchBcncList() {
      try {
        const {
          value: { tsBcncList },
        } = await getAccountInfo(this.searchKeyword, null, null);
        this.bcncList = tsBcncList;
      } catch (e) {
        console.error("getAccountInfo.err.===>", e);
      }
    },
    getBcncList() {
      // this.isSelector = true
      this.fetchBcncList();
    },
    addBcncList() {
      this.isSelector = false;
    },
    numberTemplate() {
      return {
        template: Vue.component("editOption", {
          template: "<span>{{parseInt(data.index) + 1}}</span>",
          data() {
            return {
              data: {
                data: {},
              },
            };
          },
        }),
      };
    },
    onPostCodeClick() {
      this.onDaumPostcodePopupOpen();
    },
    onDaumPostcodePopupOpen() {
      this.isDaumPostcodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    daumPostcodePopupClosed: function () {
      this.isDaumPostcodePopupOpen = false;
      this.postRerender = false;
      this.$nextTick(() => {
        this.postRerender = true;
      });
    },
    daumPostcodePopupConfirmed: function (data) {
      this.bcncPopupAddData.postNo = data.zonecode;
      this.bcncPopupAddData.addr1 = data.address;
      this.daumPostcodePopupClosed();
    },
    onSaveBcncInfo() {
      console.log(this.bcncPopupAddData);
      Object.keys(this.bcncPopupAddData).forEach((index) => {
        this.bcncPopupAddData[index] = null;
      });
    },
    confirmBcncInfo() {
      if (this.$refs.bcncListGrid.getSelectedRecords().length > 0) {
        console.log("here");
        this.$refs.createBcncSelectPopup.hide();
        this.$emit("confirmBcnc", this.$refs.bcncListGrid.getSelectedRecords());
      }
    },
    onSearchKeywordKeyDownEnter() {
      this.getBcncList();
    },
    onBcncListGridDoubleClick() {
      this.confirmBcncInfo();
    },
    onBcncListGridDataBound() {
      if (this.bcncList.length > 0) {
        this.searchKeywordTabIndex = -1;
        this.$refs.bcncListGrid.selectRow(0);
      } else {
        this.searchKeywordTabIndex = 0;
        document.getElementById("bcncSelectPopupSearchKeywordTextBox").select();
      }
    },
  },
};
</script>

<style scoped></style>
